$mdc-theme-primary: #05164d;
$mdc-theme-secondary: #787878;
$mdc-theme-background: #eaeff5;
$mdc-typography-font-family: 'Open Sans', sans-serif;

$mdc-layout-grid-breakpoints: (
        desktop: 1024px,
        tablet: 480px,
        phone: 0px
);

// Flexperto custom properties
$flx-border-radius: 8px;
$flx-border-plain: #eaeaea;
$flx-border-primary: $mdc-theme-primary;
$flx-theme-success: #00a65a;
$flx-theme-danger: #dd4b39;
$flx-theme-warning: #f39c12;
$flx-theme-text: #57626B;
$flx-theme-background: #F7F8FA;

$flx-theme-phone-accept: #4CD964;
$flx-theme-phone-decline: #F53333;
$flx-theme-phone-message: $flx-theme-text;
