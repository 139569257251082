@import "/var/www/albatros/project/albatros/web/src/theme/_variables.scss";@import "/var/www/albatros/project/albatros/web/src/dashboard/scss/dashboard.scss";
/**
 * Flags taken from: https://github.com/lukaswhite/World-Flag-Sprites
 * Usage:
 *  just add class="has-flag flag-de" to the element which should be flagged
 *  just add class="flag flag-de" to the element which should be a flag
 */
@mixin languageFlag($languages, $backgroundPosition) {
    @each $language in $languages {

        .flag.flag-#{$language},
        .has-flag.flag-#{$language}:before {
            background-position: $backgroundPosition;
        }
    }
}

.flag,
.has-flag:before {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('./flags-sprite.png') no-repeat;
}

.has-flag:before {
    content: '';
    vertical-align: middle;
}

.flag.is-inactive {
    opacity: .5;
}

.flag-container {
    display: flex;
}

$flags: (
    (ad, 0 0),
    (ae, -24px 0),
    (af, -48px 0),
    (ag, -72px 0),
    (ai, -96px 0),
    (al, -120px 0),
    (am, -144px 0),
    (an, -168px 0),
    (ao, -192px 0),
    (aq, -216px 0),
    (ar, -240px 0),
    (as, -264px 0),
    (at, -288px 0),
    (au, -312px 0),
    (aw, -336px 0),
    (ax, -360px 0),
    (az, 0 -24px),
    (ba, -24px -24px),
    (bb, -48px -24px),
    (bd, -72px -24px),
    (be, -96px -24px),
    (bf, -120px -24px),
    (bg, -144px -24px),
    (bh, -168px -24px),
    (bi, -192px -24px),
    (bj, -216px -24px),
    (bl, -240px -24px),
    (bm, -264px -24px),
    (bn, -288px -24px),
    (bo, -312px -24px),
    (br, -336px -24px),
    (bs, -360px -24px),
    (bt, 0 -48px),
    (bw, -24px -48px),
    (by, -48px -48px),
    (bz, -72px -48px),
    (ca, -96px -48px),
    (cc, -120px -48px),
    (cd, -144px -48px),
    (cf, -168px -48px),
    (cg, -192px -48px),
    (ch, -216px -48px),
    (ci, -240px -48px),
    (ck, -264px -48px),
    (cl, -288px -48px),
    (cm, -312px -48px),
    (cn, -336px -48px),
    (co, -360px -48px),
    (cr, 0 -72px),
    (cu, -24px -72px),
    (cv, -48px -72px),
    (cw, -72px -72px),
    (cx, -96px -72px),
    (cy, -120px -72px),
    (cz, -144px -72px),
    (de, -168px -72px),
    (dj, -192px -72px),
    (dk, -216px -72px),
    (dm, -240px -72px),
    (do, -264px -72px),
    (dz, -288px -72px),
    (ec, -312px -72px),
    (ee, -336px -72px),
    (eg, -360px -72px),
    (eh, 0 -96px),
    (er, -24px -96px),
    (es, -48px -96px),
    (et, -72px -96px),
    (eu, -96px -96px),
    (fi, -120px -96px),
    (fj, -144px -96px),
    (fk, -168px -96px),
    (fm, -192px -96px),
    (fo, -216px -96px),
    (fr, -240px -96px),
    (ga, -264px -96px),
    (en, -288px -96px),
    (gb, -288px -96px),
    (gd, -312px -96px),
    (ge, -336px -96px),
    (gg, -360px -96px),
    (gh, 0 -120px),
    (gi, -24px -120px),
    (gl, -48px -120px),
    (gm, -72px -120px),
    (gn, -96px -120px),
    (gq, -120px -120px),
    (gr, -144px -120px),
    (gs, -168px -120px),
    (gt, -192px -120px),
    (gu, -216px -120px),
    (gw, -240px -120px),
    (gy, -264px -120px),
    (hk, -288px -120px),
    (hn, -312px -120px),
    (hr, -336px -120px),
    (ht, -360px -120px),
    (hu, 0 -144px),
    (ic, -24px -144px),
    (id, -48px -144px),
    (ie, -72px -144px),
    (il, -96px -144px),
    (im, -120px -144px),
    (in, -144px -144px),
    (iq, -168px -144px),
    (ir, -192px -144px),
    (is, -216px -144px),
    (it, -240px -144px),
    (je, -264px -144px),
    (jm, -288px -144px),
    (jo, -312px -144px),
    (jp, -336px -144px),
    (ja, -336px -144px),
    (ke, -360px -144px),
    (kg, 0 -168px),
    (kh, -24px -168px),
    (ki, -48px -168px),
    (km, -72px -168px),
    (kn, -96px -168px),
    (kp, -120px -168px),
    (kr, -144px -168px),
    (ko, -144px -168px),
    (kw, -168px -168px),
    (ky, -192px -168px),
    (kz, -216px -168px),
    (la, -240px -168px),
    (lb, -264px -168px),
    (lc, -288px -168px),
    (li, -312px -168px),
    (lk, -336px -168px),
    (lr, -360px -168px),
    (ls, 0 -192px),
    (lt, -24px -192px),
    (lu, -48px -192px),
    (lv, -72px -192px),
    (ly, -96px -192px),
    (ma, -120px -192px),
    (mc, -144px -192px),
    (md, -168px -192px),
    (me, -192px -192px),
    (mf, -216px -192px),
    (mg, -240px -192px),
    (mh, -264px -192px),
    (mk, -288px -192px),
    (ml, -312px -192px),
    (mm, -336px -192px),
    (mn, -360px -192px),
    (mo, 0 -216px),
    (mp, -24px -216px),
    (mq, -48px -216px),
    (mr, -72px -216px),
    (ms, -96px -216px),
    (mt, -120px -216px),
    (mu, -144px -216px),
    (mv, -168px -216px),
    (mw, -192px -216px),
    (mx, -216px -216px),
    (my, -240px -216px),
    (mz, -264px -216px),
    (na, -288px -216px),
    (nc, -312px -216px),
    (ne, -336px -216px),
    (nf, -360px -216px),
    (ng, 0 -240px),
    (ni, -24px -240px),
    (nl, -48px -240px),
    (no, -72px -240px),
    (np, -96px -240px),
    (nr, -120px -240px),
    (nu, -144px -240px),
    (nz, -168px -240px),
    (om, -192px -240px),
    (pa, -216px -240px),
    (pe, -240px -240px),
    (pf, -264px -240px),
    (pg, -288px -240px),
    (ph, -312px -240px),
    (pk, -336px -240px),
    (pl, -360px -240px),
    (pn, 0 -264px),
    (pr, -24px -264px),
    (ps, -48px -264px),
    (pt, -72px -264px),
    (pw, -96px -264px),
    (py, -120px -264px),
    (qa, -144px -264px),
    (ro, -168px -264px),
    (rs, -192px -264px),
    (ru, -216px -264px),
    (rw, -240px -264px),
    (sa, -264px -264px),
    (sb, -288px -264px),
    (sc, -312px -264px),
    (sd, -336px -264px),
    (sv, -360px -264px),
    (sg, 0 -288px),
    (sh, -24px -288px),
    (sl, -48px -288px),
    (sk, -72px -288px),
    // (sl, -96px -288px),
    (sm, -120px -288px),
    (sn, -144px -288px),
    (so, -168px -288px),
    (sr, -192px -288px),
    (ss, -216px -288px),
    (st, -240px -288px),
    // (sv, -264px -288px),
    (sy, -288px -288px),
    (sz, -312px -288px),
    (tc, -336px -288px),
    (td, -360px -288px),
    (tf, 0 -312px),
    (tg, -24px -312px),
    (th, -48px -312px),
    (tj, -72px -312px),
    (tk, -96px -312px),
    (tl, -120px -312px),
    (tm, -144px -312px),
    (tn, -168px -312px),
    (to, -192px -312px),
    (tr, -216px -312px),
    (tt, -240px -312px),
    (tv, -264px -312px),
    (tw, -288px -312px),
    (tz, -312px -312px),
    (uk, -336px -312px),
    (ug, -360px -312px),
    (us, 0 -336px),
    (uy, -24px -336px),
    (uz, -48px -336px),
    (va, -72px -336px),
    (vc, -96px -336px),
    (ve, -120px -336px),
    (vg, -144px -336px),
    (vi, -168px -336px),
    (vn, -192px -336px),
    (vu, -216px -336px),
    (wf, -240px -336px),
    (ws, -264px -336px),
    (ye, -288px -336px),
    (yt, -312px -336px),
    (za, -336px -336px),
    (zm, -360px -336px),
    (zw, 0 -360px)
);

@each $languages,
$backgroundPosition in $flags {
    @include languageFlag($languages, $backgroundPosition);
}
